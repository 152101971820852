<script setup>
import { computed } from "vue";
import carouselsData from "../carousels.json";

const carousels = computed(() => {
  let carousels = carouselsData.filter((c) => c.active);
  return carousels;
});

const image = (image) => {
  return `https://geneticmisc.s3.amazonaws.com/dastefi/${image}`;
};

const whatsAppMessage = (message) => {
  return `https://api.whatsapp.com/send?phone=50685833182&text=${message}`;
};
</script>
<template>
  <div class="ps-banner">
    <div class="rev_slider fullscreenbanner" id="home-banner">
      <ul>
        <li
          v-for="carousel in carousels"
          :key="carousel.id"
          class="ps-banner"
          data-index="rs-2972"
          data-transition="random"
          data-slotamount="default"
          data-hideafterloop="0"
          data-hideslideonmobile="off"
          data-rotate="0"
        >
          <img
            class="rev-slidebg"
            :src="image(carousel.image)"
            alt=""
            data-bgposition="center center"
            data-bgfit="contain"
            data-bgrepeat="no-repeat"
            data-bgparallax="5"
            data-no-retina
          />
          <div
            class="tp-caption ps-banner__description"
            id="layer211"
            data-x="['left','left','left','left']"
            data-hoffset="['-60','15','15','15']"
            data-y="['middle','middle','middle','middle']"
            data-voffset="['30','50','50','50']"
            data-type="text"
            data-responsive_offset="on"
            data-textAlign="['center','center','center','center']"
            data-frames='[{"delay":1200,"speed":1500,"frame":"0","from":"x:50px;opacity:0;","to":"o:1;","ease":"Power3.easeInOut"},{"delay":"wait","speed":300,"frame":"999","to":"x:50px;opacity:0;","ease":"Power3.easeInOut"}]'
          ></div>
          <a
            class="tp-caption ps-btn"
            id="layer31"
            :href="whatsAppMessage(carousel.message)"
            target="_blank"
            data-x="['right','right','right','right']"
            data-hoffset="['-60','-40','-40','-40']"
            data-y="['middle','middle','middle','middle']"
            data-voffset="['120','140','200','200']"
            data-type="text"
            data-responsive_offset="on"
            data-textAlign="['center','center','center','center']"
            data-frames='[{"delay":1500,"speed":1500,"frame":"0","from":"x:50px;opacity:0;","to":"o:1;","ease":"Power3.easeInOut"},{"delay":"wait","speed":300,"frame":"999","to":"x:50px;opacity:0;","ease":"Power3.easeInOut"}]'
          >
            Comprar<i class="ps-icon-next"></i>
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>
