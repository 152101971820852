<script setup>
import { defineProps, computed } from "vue";

const props = defineProps(["product"]);
const productImage = computed(() => {
  if (props?.product?.reference?.reference) {
    return `https://geneticmisc.s3.amazonaws.com/dastefi/2024/styles/${props?.product?.reference?.reference}-min.jpg`;
  } else {
    return `https://geneticmisc.s3.amazonaws.com/dastefi/2024/styles/placeholder-min.jpg`;
  }
});

const transformedPrice = computed(() => {
  const originalPrice = props.product.price[0].price;
  if (props.product.promo) return originalPrice - originalPrice * 0.35;
  else return originalPrice;
});

// const showSizes = computed(() => {
//   return props.product.show_sizes === "true";
// });

// const getSizes = computed(() => {
//   return props.product.sizes.join(", ");
// });

const sanitizeName = (productName) => {
  return productName
    .toLowerCase()
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};

function requestForProductInfoWhatsApp() {
  const message = `https://api.whatsapp.com/send?phone=50685833182&text=Hola, me gustaría saber la disponibilidad del estilo ${props.product.name}`;
  window.open(message, "_blank");
}
</script>
<template>
  <div class="ps-shoe mb-30">
    <div class="ps-shoe__thumbnail">
      <a class="ps-shoe__favorite" href="#"><i class="ps-icon-heart"></i></a
      ><img :src="productImage" alt="" />
      <img
        v-if="props.product.promo"
        src="/images/icons/35off.png"
        alt="Promo
      Icon"
        class="promo-icon"
        style="width: 100px"
      />
      <img
        v-if="props.product.new_collection"
        src="/images/icons/new_product.png"
        alt="Promo
      Icon"
        class="promo-icon"
        style="width: 100px"
      />
      <img
        v-if="props.product.deluxe"
        src="/images/icons/deluxe.png"
        alt="Deluxe"
        class="promo-icon"
        style="width: 75px"
      />
      <div
        v-if="props.product.deluxe"
        class="discount buy-btn"
        style="background-color: #ffd700; color: black"
      >
        <a
          v-on:click="requestForProductInfoWhatsApp()"
          class="ac-slider__filter"
        >
          Comprar
        </a>
      </div>
      <div v-else class="discount buy-btn">
        <a
          v-on:click="requestForProductInfoWhatsApp()"
          class="ac-slider__filter"
        >
          Comprar
        </a>
      </div>
    </div>
    <div class="ps-shoe__content">
      <div class="ps-shoe__detail">
        <a class="ps-shoe__name">{{ sanitizeName(props.product.name) }}</a>
        <br />
        <span
          v-if="props.product.promo"
          class="ps-shoe__name"
          style="text-decoration: line-through; padding-right: 5px; color: grey"
          >₡{{ props.product.price[0].price }}</span
        >
        <span class="ps-shoe__name">₡{{ transformedPrice }}</span>
      </div>
    </div>
  </div>
</template>
<style scoped>
.ask-btn {
  padding: 1px 15px;
  margin-top: 15px;
  font-size: 12px;
}
.back-soon {
  background-color: orange;
  position: absolute;
  bottom: 6px;
  padding: 10px;
  color: #fff;
}
.sizes {
  background-color: #676b2e;
  color: #ffffff;
  margin: 5px 5px 0 0;
  padding: 3px;
  border-radius: 30%;
}
.discount {
  position: absolute;
  bottom: 6px;
  padding: 10px;
  background-color: red;
  color: #fff;
}
.buy-btn {
  background-color: #676b2e;
  cursor: pointer;
  bottom: 6px;
  padding: 10px;
}
.promo-buy-btn {
  background-color: red;
  cursor: pointer;
  bottom: 6px;
  padding: 10px;
}

.promo-icon {
  position: absolute;
  top: 0;
  left: 0;
}

@keyframes blink {
  0% {
    border-color: white;
  }
  50% {
    border-color: pink;
  }
  100% {
    border-color: white;
  }
}

.blinking {
  border: 4px solid white;
  animation: blink 1s linear infinite;
}
</style>
